import React from 'react';
import firebase from "./DbFirestore";

//Est� sendo chamado no HomeAdmin apos o Login do Administrador.

const db = firebase.firestore();

export default function GetContras() {

    let names = [];
    let users = db.collection("users");  
    users.get()
    .then(querySnapshot => {
        querySnapshot.forEach((doc) => {
            if (doc.exists) {
  
              names.push(doc.data())
              sessionStorage.setItem("Names", JSON.stringify(names));
  
            } else {
                console.log("No such document!");
            }
        });
    });

    let contracheques = db.collection("contracheques");
    let dados = [];
    contracheques.get()
        .then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                if (doc.exists) {

                    let years = Object.keys(doc.data());

                    years.forEach(
                        (item1) => {

                            let months = Object.keys(doc.data()[item1])

                            months.forEach(
                                (item2) => {

                                    dados.push(doc.data()[item1][item2][0]);

                                })

                        }
                    )


                    sessionStorage.setItem("Length", dados.length);
                    sessionStorage.setItem("Funcionarios", JSON.stringify(dados));

                } else {
                    console.log("No such document!");
                }
            });
        });


    return (
        <>
        </>

    )

}


