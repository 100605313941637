import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid } from '@material-ui/core';
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Link } from 'react-router-dom';

import logo from '../assets/logooba.png';

import { logout } from "../services/auth";
import GetContrasUser from '../services/GetContrasUser';
import FirstLog from '../components/FirstLog';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 'bold',
    color: 'white',
  },

  hello: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 20,
    marginRight: theme.spacing(5),

  },

  exit: {
    marginLeft: theme.spacing(2),
  },
  
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 160,
    marginTop:30,
  },
  mensagem :{
 
    textAlign: 'center',
    textJustify: 'center'
  }

}));

export default function HomeUsers() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const GivenName = localStorage.getItem('GivenName')

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ background: '#228B22' }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Portal - OBA
          </Typography>

          <IconButton onClick={logout} component={Link} to="/"  edge="start" className={classes.exit} color="inherit" aria-label="people">
             <ExitToAppIcon fontSize="large" />
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />

        <List>
            <ListItem button component={Link} to="/ContraCheques">
              <ListItemIcon>
                  <FolderSharedOutlinedIcon fontSize = 'large' />
             </ListItemIcon>
              <ListItemText primary='Contra Cheques' />
            </ListItem>
        </List>

      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />

            <Typography className={classes.hello} color="textSecondary" gutterBottom>
                Olá, {GivenName[0].toUpperCase() + GivenName.slice(1).toLowerCase()}!
            </Typography>

            <Typography className={classes.mensagem} color="textSecondary" gutterBottom>
              Você é Usuário(a) desse Portal. Nele você pode visualizar em qualquer momento e lugar,
              os seus Contra-Cheques.
            </Typography>

            <Typography className={classes.mensagem} color="textSecondary" gutterBottom>
              Favor começar sua navegação pelo menu ao lado.
            </Typography>

            <Grid container direction="row" justify="center" alignItems="center">
              <img className={classes.logo} src={logo} alt="DCB" />
            </Grid>            

      </main>
     

      <GetContrasUser />

      {
        localStorage.getItem("firstLog") === 'true' ? <FirstLog /> : ''
      }

      

    </div>
  );
}
