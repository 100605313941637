import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { useHistory } from 'react-router';

import firebase from "../services/DbFirestore";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Cadtheme = createMuiTheme({
    palette: {

        primary: {
            // This is green.A700 as hex.
            main: '#006400',
        }

    },
});

const useStyles = makeStyles((Cadtheme) => ({
    root: {

        padding: Cadtheme.spacing(1),

    },

    pos: {
        marginBottom: 12,

    },

    content: {
        display: 'flex',
        borderRadius: 20,
        borderColor: '#0047B1',
        width: 300,

    },

    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 40,
        fontWeight: 'bold',

    },

}));

export default function FirstLog() {
    const classes = useStyles();
    let history = useHistory();

    const [open, setOpen] = React.useState(true);
    const [Nome, setNome] = React.useState(localStorage.getItem('displayName'));
    const [Email, setEmail] = React.useState(localStorage.getItem('Email'));
    const [Senha, setSenha] = React.useState('');    
    const [ConfSenha, setConfSenha] = React.useState('');    

    const auth = firebase.auth();
    const db = firebase.firestore();

    const id = localStorage.getItem('Id');

    async function handleAlter(e){
        
        if (Senha !== '' && ConfSenha !== '' && Senha === ConfSenha ) {

            auth.currentUser.updatePassword(Senha).then( () => {

                const userRef = db.doc(`users/${id}`);

                userRef.set({

                  alteracao:Date(),
                  firstLog: false,
        
                }, { merge: true });


                alert('Senha alterada com sucesso!')
                
                history.push("/");

              
                
            }).catch(function (error) {
                console.log(error)
                alert(error.message)
            });


        }else{
            alert('As senhas não conferem!');
            setSenha('');
            setConfSenha('');
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.title} id="alert-dialog-slide-title">
                    {"Alterar sua senha:"}
                </DialogTitle>


                <Typography className={classes.pos} color="textSecondary" component={'span'}>

                    <form className={classes.root} noValidate autoComplete="off">

                        <ThemeProvider theme={Cadtheme}>
                            <TextField
                                disabled
                                id="Nome"
                                value={Nome.toUpperCase()}
                                onChange={(e) => setNome(e.target.value)}
                                onKeyPress={(ev) => {
                                    //console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        // Do code here
                                        const form = ev.target.form;
                                        const index = Array.prototype.indexOf.call(form, ev.target);
                                        form.elements[index + 1].focus();
                                        ev.preventDefault();
                                    }
                                }}
                                label="Nome Completo"
                                style={{ margin: 20, width: 240 }}
                                margin="normal"
                                color="primary"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                disabled
                                id="Email"
                                type="email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={(ev) => {
                                    //console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        // Do code here
                                        const form = ev.target.form;
                                        const index = Array.prototype.indexOf.call(form, ev.target);
                                        form.elements[index + 1].focus();
                                        ev.preventDefault();
                                    }
                                }}
                                label="E-mail"
                                style={{ margin: 20, width: 240 }}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Grid container direction="row" justify="center" alignItems="center">

                                <TextField
                                    required
                                    id="Senha"
                                    type="password"
                                    value={Senha}
                                    onChange={(e) => setSenha(e.target.value)}
                                    label="Senha"
                                    style={{ margin: 20, width: 240 }}
                                    margin="normal"
                                    color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={
                                        { maxLength: 15 }
                                    }
                                />

                                <TextField
                                    required
                                    id="ConfSenha"
                                    type="password"
                                    value={ConfSenha}
                                    onChange={(e) => setConfSenha(e.target.value)}
                                    onKeyPress={(ev) => {
                                        //console.log(`Pressed keyCode ${ev.key}`);
                                        if (ev.key === 'Enter') {
                                          // Do code here
                                          handleAlter()
                                          ev.preventDefault();
                                        }
                                      }}
                                    label="Confirmar Senha"
                                    style={{ margin: 20, width: 240 }}
                                    margin="normal"
                                    color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={
                                        { maxLength: 15 }
                                    }
                                />

                            </Grid>

                        </ThemeProvider>

                    </form>

                </Typography>


                <DialogActions>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Button
                            onClick={handleAlter}
                            style={{ background: 'linear-gradient(45deg, #006400 30%, #228B22 90%)', borderRadius: 10, backgroundColor: "#006400", borderColor: "#006400", color: "#ffffff", width: 200 }}
                            variant="contained"
                            size="large"
                        >
                            Alterar
                    </Button>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
