import firebase from "../services/DbFirestore";

export const TOKEN_KEY = "@oba-Token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {


  firebase.auth().signOut()
    .then( () => {
    localStorage.setItem('Log', 'Sign-out successful');
  }).catch( (error)=> {
    localStorage.setItem('Log', error);
  });

  localStorage.clear();
  sessionStorage.clear();

  window.location.reload(false);
  
};


