import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logout } from "../services/auth";
import ContraCheques from '../components/ContraCheques';
import ContraChequesUser from '../components/ContraChequesUser'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   
  },
  exit: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 'bold',
    color: 'white',
  },

  hello: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginRight: theme.spacing(5),

  },

  file:{
    marginTop: 30,
    justifyItems: 'center',

  },



}));

export default function ContraPage() {
  const classes = useStyles();
  const groups = localStorage.getItem('Group')

  return (

    <div className={classes.root}>
      <AppBar style={{ background: '#228B22' }} position="static">
        <Toolbar>

          <Typography variant="h6" className={classes.title}>
            Portal do Colaborador - OBA
          </Typography>

          <IconButton onClick={logout} component={Link} to="/"  edge="start" className={classes.exit} color="inherit" aria-label="people">
             <ExitToAppIcon fontSize="large" />
          </IconButton>
          
        </Toolbar>
      </AppBar>


          <div >

          {
            groups === 'Admin' ? <ContraCheques /> : <ContraChequesUser/>
          }


          </div>
          

    </div>    
    
  );
}
