import React from 'react';
import firebase from "./DbFirestore";

//Est� sendo chamado no HomeAdmin apos o Login do Administrador.

const db = firebase.firestore();

export default function GetContrasUser() {

    const displayName = localStorage.getItem('displayName')

    let contracheques = db.collection("contracheques");
    let dados = [];
    contracheques.get()
        .then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                if (doc.exists && doc.id === displayName) {

                    let years = Object.keys(doc.data());

                    years.forEach(
                        (item1) => {

                            let months = Object.keys(doc.data()[item1])

                            months.forEach(
                                (item2) => {

                                    dados.push(doc.data()[item1][item2][0]);

                                })

                        }
                    )


                    
                    sessionStorage.setItem("contracheques", JSON.stringify(dados));

                } else {
                    console.log("No such document!");
                }
            });
        });

  
    return (
        <>
        </>

    )

}


