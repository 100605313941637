import * as firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/';
import "firebase/auth";
import "firebase/firestore";
  
  // Your web app's Firebase configuration
  const config = {
    apiKey: "AIzaSyAFxdwkos98lu86Ivbt1YoHK1kPpTl_-KE",
    authDomain: "oba-colaboradores.firebaseapp.com",
    databaseURL: "https://oba-colaboradores.firebaseio.com",
    projectId: "oba-colaboradores",
    storageBucket: "oba-colaboradores.appspot.com",
    messagingSenderId: "310108539054",
    appId: "1:310108539054:web:0a578df8cbda498c22053f",
    measurementId: "G-9P5HSDK6C9"
  };

  // Initialize Firebase
  firebase.initializeApp(config);

  //firebase.analytics();

export default firebase;