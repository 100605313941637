import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import firebase from "../services/DbFirestore";
import GetContras from '../services/GetContras'

const storage = firebase.storage();
const db = firebase.firestore();


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function ContraChequeUpload() {
  const classes = useStyles();
  let history = useHistory();

  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [funcionario, setFuncionario] = React.useState('');

  useEffect(() => {

    if (month && year && funcionario !== '') {

      console.log(month + ' - ' + year + ' - ' + funcionario);

    }
  }, [month, year, funcionario]);

  const names = JSON.parse(sessionStorage.getItem('Names'))

  const handleChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  async function handleUpload(){

    if (image !== null) {

      const uploadTask = storage.ref(`contracheques/${funcionario+'_'+month+year+'_'+image.name}`)
            .put(image);

      const userRef = db.collection('contracheques').doc(funcionario.toUpperCase());
    

      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("contracheques")
            .child(funcionario+'_'+month+year+'_'+image.name)
            .getDownloadURL()
            .then(url => {
              setUrl(url);

              userRef.set({
   
                [year] : {
                  [month]: [{
                    funcionario: funcionario.toUpperCase(),
                    file: funcionario+'_'+month+year+'_'+image.name,
                    inclusao: Date(),   
                    url: url,
                    mes: month, 
                    ano: year, 
                  }]
                }

              }, { merge: true });

              alert("Dados salvos com sucesso!");

              history.push("/AddContraCheques");
              
            });
           
        }

        
      );
     


    } else {
      alert('Por favor, escolha um arquivo!')
    }

  }

  console.log("image: ", image);

  return (

    <div className={classes.root}>

      <FormControl shrink className={classes.formControl}>
        <InputLabel id="month">Mês</InputLabel>
        <Select
          labelId="month"
          id="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          style={{ width: 100 }}
        >
          <MenuItem value={'1'}>Janeiro</MenuItem>
          <MenuItem value={'2'}>Fevereiro</MenuItem>
          <MenuItem value={'3'}>Março</MenuItem>
          <MenuItem value={'4'}>Abril</MenuItem>
          <MenuItem value={'5'}>Maio</MenuItem>
          <MenuItem value={'6'}>Junho</MenuItem>
          <MenuItem value={'7'}>Julho</MenuItem>
          <MenuItem value={'8'}>Agosto</MenuItem>
          <MenuItem value={'9'}>Setembro</MenuItem>
          <MenuItem value={'10'}>Outubro</MenuItem>
          <MenuItem value={'11'}>Novembro</MenuItem>
          <MenuItem value={'12'}>Dezembro</MenuItem>
        </Select>
      </FormControl>

      <FormControl shrink className={classes.formControl}>
        <TextField
          type="number"
          id="standard-basic"
          label="Ano"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          style={{ width: 100, height: 45 }}
        />
      </FormControl>

      <br />

      <FormControl shrink className={classes.formControl}>
        <InputLabel id="funcionario">Funcionário</InputLabel>
        <Select
          labelId="funcionario"
          id="funcionario"
          value={funcionario}
          onChange={(e) => setFuncionario(e.target.value)}
          style={{ width: 400, height: 45 }}
        >

          {

            Object.values(names).map((text) => 
            
            <MenuItem value={text.displayName}>{text.displayName}</MenuItem>
            
              )

          }


        </Select>
      </FormControl>


      <br />
      <br />

      <input
        accept="file/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        <Button startIcon={<AttachFileOutlinedIcon />} variant="contained" color="primary" component="span">
          Escolher
        </Button>
      </label>


      <Button
        variant="contained"
        style={{ background: 'linear-gradient(45deg, #006400 30%, #228B22 90%)', borderRadius: 10, backgroundColor: "#006400", borderColor: "#006400", color: "#ffffff" }}
        onClick={handleUpload}
        startIcon={<SaveIcon />}
      >
        Salvar
        </Button >

      <br />

      <p>{image !== null ? funcionario+'_'+month+year+'_'+image.name : ''}</p>

      <br />

      <progress value={progress} max="100" />

      <br />
      { url }
      <br />

      <GetContras/>

    </div>
  );
};