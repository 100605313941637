import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router';

import logo from '../assets/logooba.png';


import { login,logout } from "../services/auth";
import { LinearProgress } from '@material-ui/core';

import firebase from "../services/DbFirestore";

const auth = firebase.auth();
const db = firebase.firestore();

const Logintheme = createMuiTheme({
  palette: {

    primary: {
      // This is green.A700 as hex.
      main: '#006400',
    }

  },
});

const useStyles = makeStyles((Logintheme)=>({
  root: {
 
    padding: Logintheme.spacing(1), 
    
  },

  pos: {
    marginBottom: 12,
    
  },

  content: {
    display: 'flex', 
    borderRadius:20,
    borderColor:'#006400',
    width: 300,   
    
  },

  title: {
    display: 'flex',  
    justifyContent: 'center',
    fontSize: 30,
    fontWeight: 'bold',
    
  },

  Loginlogo: {
    width: 160,
    display: 'flex',
    padding: Logintheme.spacing(7),    

  },
  
  barColorPrimary: {
    backgroundColor: '#006400',
  }

}));



function Login() {

  const classes = useStyles();

  const [Nome, setNome] = React.useState('');
  const [Senha, setSenha] = React.useState('');
  const [Loading, setLoading] = React.useState(false);
  
  let history = useHistory()

  async function handleLogin(e){

    if (Nome || Senha) {

      setLoading(true)

      try { 

        auth.signInWithEmailAndPassword(Nome, Senha)
        .then( async res => {

          const token = await Object.entries(res.user)[5][1].b
          const id = await Object.entries(res.user)[5][1].b.h

          await login(token);  

          localStorage.setItem("Id", id);
         
          let docRef = db.collection("users").doc(id);

          docRef.get()
            .then((doc) => {
              if (doc.exists) {

                localStorage.setItem("Group", doc.data().groups);
                localStorage.setItem("CPF", doc.data().CPF);
                localStorage.setItem("Email", doc.data().email);
                localStorage.setItem("GivenName", doc.data().displayName.substring(0, doc.data().displayName.indexOf(" ")) ); 
                localStorage.setItem("displayName", doc.data().displayName ); 
                localStorage.setItem("firstLog", doc.data().firstLog ); 

                doc.data().groups === 'Colaboradores' ? history.push("/homeUser") : history.push("/homeAdm");

                console.log("Document data:", doc.data());


              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch( (error)=> {
            console.log("Error getting document:", error);
        });
        
         

        })
        .catch(err => {
          alert(err.message)
          logout(); 
          setLoading(false)
        })           


      }catch (err) {
        console.log(err)
        alert(err)
        alert("Houve um problema com o login, verifique suas credenciais.");
        logout(); 
        setLoading(false)
      }
      
    } else {
      alert("Preencha e-mail e senha para continuar!");
      logout();
      setLoading(false)
    }

    setNome('');
    setSenha('');    

  }
  
  return (

    <Grid style = {{marginTop: 1}} container justify = "center">
    <main className={classes.content}>
     
    <Card >
   
      <CardContent>
      <img className={classes.Loginlogo} src={logo} alt="DCB" />
        <Typography className={classes.title} variant="h5" component="h2" gutterBottom>
          Login
        </Typography>

        <Typography className={classes.pos} color="textSecondary" component={'span'}>

          <form className={classes.root} noValidate autoComplete="off">

          <ThemeProvider theme={Logintheme}>
            <TextField
              required
              id="Nome"
              value={Nome.toLowerCase()}
              onChange={(e) => setNome(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  const form = ev.target.form;
                  const index = Array.prototype.indexOf.call(form, ev.target);
                  form.elements[index + 1].focus();
                  ev.preventDefault();
                }
              }}
              autoFocus 
              label="Email"
              type="email"
              style={{ margin: 20,width: 240 }}              
              margin="normal"
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
            />


            <TextField
              required
              id="Senha"
              type="password"
              value={Senha}
              onChange={(e) => setSenha(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  handleLogin()
                  ev.preventDefault();
                }
              }}
              label="Senha"
              style={{ margin: 20,width: 240 }}              
              margin="normal"
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
            />

            </ThemeProvider>

          </form>

        </Typography>

      </CardContent>
      
      <CardActions style = {{justifyContent: "center"}}>
        <Button           
          onClick={handleLogin} 
          style={{ background: 'linear-gradient(45deg, #006400 30%, #228B22 90%)',borderRadius:10,backgroundColor:"#006400",borderColor: "#006400",color:"#ffffff",width:200 }} 
          variant="contained"  
          size="large">
            LOGIN
        </Button>
        
      </CardActions>      
          
     {Loading ? <LinearProgress  className={classes.barColorPrimary} />:''}


    </Card>
    </main>
    

    </Grid>
  );
}

Login.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(Login);
