import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router';

import firebase from "../services/DbFirestore";

const Cadtheme = createMuiTheme({
  palette: {

    primary: {
      // This is green.A700 as hex.
      main: '#006400',
    }

  },
});

const useStyles = makeStyles((Cadtheme)=>({
  root: {
 
    padding: Cadtheme.spacing(1),   
    
  },

  pos: {
    marginBottom: 12,
        
  },

  content: {
    display: 'flex', 
    borderRadius:20,
    borderColor:'#006400',
    width: 700,   
    marginTop: 10,
    
  },

  title: {
    display: 'flex',  
    justifyContent: 'center',
    fontSize: 30,
    fontWeight: 'bold',
    
  },

}));

function CompUser() {
  const classes = useStyles();

  const auth = firebase.auth();
  const db = firebase.firestore();

  const [Cpf, setCpf] = React.useState('');
  const [Nome, setNome] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Cont, setCont] = React.useState('');
  const [Senha, setSenha] = React.useState('');
  let history = useHistory()
  
  async function handleLogin(e){

    if (Email || Cpf || Nome || Senha) {
      try { 


        const {user} = await auth.createUserWithEmailAndPassword(Email, Senha)
    
        const userRef = db.doc(`users/${user.uid}`);

        await userRef.set({
          CPF:Cpf,
          displayName: Nome.toUpperCase(),
          email: Email,
          groups: "Colaboradores",
          inicio: Cont,
          inclusao:Date(),
          firstLog: true,

        });

        
        auth.currentUser.updateProfile({
          displayName: Nome.toUpperCase(),

        })


        alert('Usuário criado com sucesso!')
  
        history.push("/homeAdm");

      }catch (err) {
        console.error(err)
        alert(err);
        
      }
      
    } else {

      alert("Existem dados incompletos!");
      
    }

    setCpf('');
    setSenha('');
    

  }
  
  return (


    <Grid style = {{marginTop: 70}} container justify = "center">
    <main className={classes.content}>
     
    <Card >
   
      <CardContent>
 
        <Typography className={classes.title} variant="h2" component="h2" gutterBottom>
           Usuário
        </Typography>

        <Typography className={classes.pos} color="textSecondary" component={'span'}>

          <form className={classes.root} noValidate autoComplete="off">

          <ThemeProvider theme={Cadtheme}>
            <TextField
              required
              id="Nome"
              value={Nome.toUpperCase()}
              onChange={(e) => setNome(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  const form = ev.target.form;
                  const index = Array.prototype.indexOf.call(form, ev.target);
                  form.elements[index + 1].focus();
                  ev.preventDefault();
                }
              }}
              label="Nome Completo"
              style={{ margin: 20,width: 550 }}              
              margin="normal"
              color="primary"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              required
              id="Email"
              type="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  const form = ev.target.form;
                  const index = Array.prototype.indexOf.call(form, ev.target);
                  form.elements[index + 1].focus();
                  ev.preventDefault();
                }
              }}
              label="E-mail"
              style={{ margin: 20,width: 550 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            
            <TextField
              required
              id="Cpf"
              value={Cpf}
              onChange={(e) => setCpf(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  const form = ev.target.form;
                  const index = Array.prototype.indexOf.call(form, ev.target);
                  form.elements[index + 1].focus();
                  ev.preventDefault();
                }
              }}
              label="Cpf"
              style={{ margin: 20,width: 260 }}              
              margin="normal"
              color="primary"
              InputLabelProps={{
                shrink: true,
          
              }}
              inputProps={
                {maxLength: 11}
              }
            />

            <TextField
              id="DtInicio"
              type="date"
              value={Cont}
              onChange={(e) => setCont(e.target.value)}
              label="Dt. Contratação"
              style={{ margin: 20,  width: 250 }} 
              placeholder="Inicio"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div style={{textAlign:'center'}}>
            <TextField
              required
              id="Senha"
              type="password"
              value={Senha}
              onChange={(e) => setSenha(e.target.value)}
              onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  handleLogin()
                  ev.preventDefault();
                }
              }}
              label="Senha"
              style={{ margin: 20,width: 240 }}              
              margin="normal"
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={
                {maxLength: 15}
              }
            />
            </div>

            </ThemeProvider>

          </form>

        </Typography>

      </CardContent>
      
    <CardActions style={{ justifyContent: "center" }}>
        <Button onClick={handleLogin} style={{ background: 'linear-gradient(45deg, #006400 30%, #228B22 90%)', borderRadius: 10, backgroundColor: "#006400", borderColor: "#006400", color: "#ffffff", width: 200 }} variant="contained" size="large">Criar</Button>
    </CardActions>


    </Card>
    </main>

    </Grid>
  );
}

CompUser.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default CompUser;
