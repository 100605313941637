import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';

let largura;
window.onresize = (e) => {
    largura = window.innerWidth;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 580,
        maxWidth: largura,
        backgroundColor: theme.palette.background.paper,
    },

    list: {
        flexGrow: 1,
        marginTop: 50,
        borderColor: '#228B22'

    },

    formControl: {
        marginTop: theme.spacing(4),
        margin: theme.spacing(1),
        minWidth: 120,

    },
    selectEmpty: {
        marginTop: theme.spacing(5),
    },

}));


export default function ContraChequesUser() {

    const classes = useStyles();

    const [month, setMonth] = React.useState('');
    const [year, setYear] = React.useState('');

    let contracheques = [];
    let result = [];

    try {

        contracheques = JSON.parse(sessionStorage.getItem('contracheques'))

        if (month === '' && year === '') {

            result = Object.keys(contracheques).map((key) => [Number(key), contracheques[key]]);

        } else if (month !== '' && year === '') {
            let filtrado = contracheques.filter((item) => item.mes === month);
            result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);

        } else if (month === '' && year !== '') {
            let filtrado = contracheques.filter((item) => item.ano === year);
            result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);

        } else {
            let filtrado = contracheques.filter((item) => item.mes === month && item.ano === year);
            result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);

        }

    } catch (err){
        alert('Você ainda nâo tem Contra Cheques cadastrados! Entre em contato com o RH.')
    }

    const anos = [

        {
            id: 1,
            ano: "2018"
        },
        {
            id: 2,
            ano: "2019"
        },
        {
            id: 3,
            ano: "2020"
        }

    ];

    return (


        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >

            <div>
                <FormControl shrink='true' className={classes.formControl}>
                    <InputLabel id="month">Mês</InputLabel>
                    <Select
                        labelId="month"
                        id="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                    >
                        <MenuItem value={'1'}>Janeiro</MenuItem>
                        <MenuItem value={'2'}>Fevereiro</MenuItem>
                        <MenuItem value={'3'}>Março</MenuItem>
                        <MenuItem value={'4'}>Abril</MenuItem>
                        <MenuItem value={'5'}>Maio</MenuItem>
                        <MenuItem value={'6'}>Junho</MenuItem>
                        <MenuItem value={'7'}>Julho</MenuItem>
                        <MenuItem value={'8'}>Agosto</MenuItem>
                        <MenuItem value={'9'}>Setembro</MenuItem>
                        <MenuItem value={'10'}>Outubro</MenuItem>
                        <MenuItem value={'11'}>Novembro</MenuItem>
                        <MenuItem value={'12'}>Dezembro</MenuItem>
                    </Select>
                </FormControl>

                <FormControl shrink='true' className={classes.formControl}>
                    <InputLabel id="year">Ano</InputLabel>
                    <Select
                        labelId="year"
                        id="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    >

                        {anos.map((text) => (
                            <MenuItem key={text.id} value={text.ano}>{text.ano}</MenuItem>

                        ))}

                    </Select>
                </FormControl>


            </div>


            <div className={classes.root}>

                <div className={classes.list}>

                    {
                        result.map(item => (


                            <ListItem button key={item[1].mes + item[1].ano}>
                                <ListItemText primary={item[1].mes + '/' + item[1].ano + ' - ' + item[1].funcionario} secondary={item[1].file} />
                                <ListItemIcon>
                                    <Button href={item[1].url} target="_blank" color="primary">
                                        <GetAppIcon />
                                    </Button>
                                </ListItemIcon>
                            </ListItem>

                        ))}

                </div>

            </div>

        </Grid>

    );
}
