import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { Grid } from '@material-ui/core';

let largura;
window.onresize = (e) => {
    largura = window.innerWidth;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 580,
        maxWidth: largura,
        backgroundColor: theme.palette.background.paper,
    },

    list: {
        flexGrow: 1,
        marginTop: 50,
        borderColor: '#228B22'

    },

    formControl: {
        marginTop: theme.spacing(4),
        margin: theme.spacing(1),
        minWidth: 120,

    },
    selectEmpty: {
        marginTop: theme.spacing(5),
    },

}));


export default function Usuarios() {

    const classes = useStyles();
    
    const [cpf, setCpf] = React.useState('');
    const [employer, setEmployer] = React.useState('');
    
    let funcionarios = [];
    let result = [];
    let filtrado = [];

    funcionarios = JSON.parse(sessionStorage.getItem('Names'))
    

    if (cpf === '' && employer === '') {        
        result = Object.keys(funcionarios).map((key) => [Number(key), funcionarios[key]]);
    }else if (cpf === '' || employer !== ''){        
        filtrado = funcionarios.filter((item) => item.displayName.match(employer) );
        result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);
    }else if (cpf !== '' || employer === ''){        
        filtrado = funcionarios.filter((item) => item.CPF.match(cpf) );
        result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);
    }else{        
        filtrado = funcionarios.filter((item) => item.CPF.match(cpf) && item.displayName.match(employer) );
        result = Object.keys(filtrado).map((key) => [Number(key), filtrado[key]]);
    }

    return (


        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >

            <div>
       
                <FormControl shrink = 'true' className={classes.formControl}>
                    <TextField
                        id="standard-basic"
                        label="Funcionário"
                        value={employer}
                        onChange={(e) => setEmployer(e.target.value.toUpperCase())}
                        style={{ width: 400, height: 45 }}
                    />
                </FormControl>

                <FormControl shrink = 'true' className={classes.formControl}>
                    <TextField
                        id="standard-basic"
                        type="number"
                        label="CPF"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                        style={{ width: 200, height: 45 }}
                    />
                </FormControl>

            </div>


            <div className={classes.root}>

                <div className={classes.list}>

                    {
                        result.map(item => (


                            <ListItem button key={item[0]}>
                                <ListItemText primary={item[1].displayName} secondary={item[1].CPF} />
                            </ListItem>

                        ))}

                </div>

            </div>

        </Grid>

    );
}
