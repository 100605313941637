import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import CompUser from '../components/Cadastro'

const useStyles = makeStyles((theme) => ({
    content:{
      textAlign: 'center',

    },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
  },
}));

function DialogUser(props) {
    const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog className={classes.content} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Cadastrar Novo Usuário</DialogTitle>
      <DialogActions >
        <CompUser />
        </DialogActions>
    </Dialog>
  );
}

DialogUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogUser() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);

  };

  return (
    <div>

      <Fab onClick={handleClickOpen} style={{ backgroundColor: '#006400' }} className={classes.fab} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      <DialogUser open={open} onClose={handleClose} />
    </div>
  );
}
